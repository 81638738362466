import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('api', 'ano-fiscal');

const anoFiscalActions = {
  obter: { method: 'GET', url: `${basePath}/{id}/obter` },
  pesquisarPorTipo: { method: 'GET', url: `${basePath}/{idTipoVerba}` },
  pesquisarPorTipoVigente: { method: 'GET', url: `${basePath}?idTipoVerba={idTipoVerba}&vigenteOuComBloqueio=true` },
  alterarStatus: { method: 'PUT', url: `${basePath}/{id}/status` },
  gravar: { method: 'POST', url: `${basePath}` },
  periodos: { method: 'GET', url: `${basePath}/{idTipoVerba}/periodo` },
  buscarPeriodosAnoFiscal: { method: 'GET', url: `${basePath}/periodo?{&idsAnoFiscal*}` },
  buscarDadosPeriodosAnoFiscal: { method: 'GET', url: `${basePath}/periodo?{&idsPeriodos*}` },
  buscar: { method: 'GET', url: `${basePath}` },
  autocompletePeriodo: { method: 'GET', url: `${basePath}/1/periodo/autocomplete` },
  periodoPaginado: { method: 'GET', url: `${basePath}/1/periodo/paginado` },
  buscarPeriodosPorTipo: { method: 'GET', url: `${basePath}/periodos-ano-fiscal?tipo_periodo={tipoPeriodo}` },
  listarAnosDisponiveis: { method: 'GET', url: `${basePath}/anos-disponiveis` },
  listarMesesDisponiveis: { method: 'GET', url: `${basePath}/meses-disponiveis/{ano}` },
};

export default (resource) => resource(`${basePath}`, {}, anoFiscalActions);
